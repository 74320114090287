import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "topic list-unstyled row mx-0 justify-content-between align-items-center" }
const _hoisted_2 = { class: "entry mb-0 col-sm-10" }
const _hoisted_3 = {
  class: "mb-0",
  style: {"word-wrap":"break-word"}
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "entry-meta mt-1" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "col-sm-2" }
const _hoisted_18 = { class: "bbp-author" }
const _hoisted_19 = ["href"]
const _hoisted_20 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("a", {
          class: "link-dark",
          href: '/community/posts/' + _ctx.post.postId
        }, _toDisplayString(_ctx.post.title), 9, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: '/community/posts/' + _ctx.post.postId
            }, _toDisplayString(_ctx.timeSince(Number(_ctx.post.created))) + " ago ", 9, _hoisted_6)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: '/community/posts/' + _ctx.post.postId
            }, [
              _createTextVNode(_toDisplayString(_ctx.formatNumber(_ctx.post.likes)) + " ", 1),
              (_ctx.post.likes === 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Like"))
                : (_openBlock(), _createElementBlock("span", _hoisted_9, "Likes"))
            ], 8, _hoisted_7)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: '/community/posts/' + _ctx.post.postId
            }, [
              _createTextVNode(_toDisplayString(_ctx.formatNumber(_ctx.post.views)) + " ", 1),
              (_ctx.post.views === 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, "View"))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, "Views"))
            ], 8, _hoisted_10)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: '/community/posts/' + _ctx.post.postId
            }, [
              _createTextVNode(_toDisplayString(_ctx.post.comments) + " ", 1),
              (_ctx.post.comments === 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, "Reply"))
                : (_openBlock(), _createElementBlock("span", _hoisted_15, "Replies"))
            ], 8, _hoisted_13)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: '/community/posts?category=' + _ctx.post.category,
              class: "badge bg-secondary text-white"
            }, _toDisplayString(_ctx.post.category), 9, _hoisted_16)
          ])
        ])
      ])
    ]),
    _createElementVNode("li", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("a", {
          href: '/user/' + _ctx.post.userId
        }, [
          _createElementVNode("img", {
            class: "rounded-circle",
            alt: "Image",
            src: _ctx.post.user?.userThumbnail ? _ctx.assetHost + '/' + _ctx.post.user?.userThumbnail : '/img/profile_not_found.png',
            onerror: "this.onerror=null; this.src='/img/profile_not_found.png'"
          }, null, 8, _hoisted_20)
        ], 8, _hoisted_19)
      ])
    ])
  ]))
}