import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "my-4",
  id: "back-to-discussion"
}
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  class: "commentlist p-0 m-0",
  style: {"border-bottom":"0 !important"}
}
const _hoisted_5 = {
  class: "comment even thread-even depth-1",
  id: "li-comment-1"
}
const _hoisted_6 = {
  id: "comment-1",
  class: "comment-wrap"
}
const _hoisted_7 = { class: "comment-meta" }
const _hoisted_8 = { class: "comment-author vcard" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "comment-content" }
const _hoisted_12 = { class: "comment-author" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "d-flex" }
const _hoisted_15 = {
  key: 0,
  class: "ms-1"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "mt-3" }
const _hoisted_20 = { class: "btn-group" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  class: "dropdown-menu dropdown-menu-links rounded shadow-sm dropdown-menu-end py-0 m-0",
  "aria-labelledby": "editlink2"
}
const _hoisted_23 = ["href"]
const _hoisted_24 = ["data-bs-target"]
const _hoisted_25 = {
  key: 0,
  class: "my-3"
}
const _hoisted_26 = {
  key: 0,
  class: "my-2 text-danger"
}
const _hoisted_27 = {
  key: 0,
  class: "my-4"
}
const _hoisted_28 = ["id"]
const _hoisted_29 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_30 = { class: "modal-content" }
const _hoisted_31 = { class: "modal-body" }
const _hoisted_32 = { class: "row p-4 bg-white d-flex align-items-center justify-content-center rounded" }
const _hoisted_33 = { class: "col-md-12 p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_Comment = _resolveComponent("Comment", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.commentLevel < 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.isShowCommentsMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("a", {
                    style: {"cursor":"pointer"},
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitShowParentCommentsEvent()))
                  }, _cache[8] || (_cache[8] = [
                    _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1),
                    _createTextVNode(" Back to Discussion ")
                  ]))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("ol", _hoisted_4, [
                _createElementVNode("li", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("a", {
                          class: "comment-avatar",
                          href: '/user/' + _ctx.comment.userId
                        }, [
                          _createElementVNode("img", {
                            alt: "Image",
                            src: _ctx.comment.user?.userThumbnail ? _ctx.assetHost + '/' + _ctx.comment.user?.userThumbnail : '/img/profile_not_found.png',
                            onerror: "this.onerror=null; this.src='/img/profile_not_found.png'",
                            class: "avatar avatar-60 photo avatar-default rounded-circle",
                            height: "60",
                            width: "60"
                          }, null, 8, _hoisted_10)
                        ], 8, _hoisted_9)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("a", {
                          href: '/user/' + _ctx.comment.userId
                        }, _toDisplayString(_ctx.comment.user?.username ?? "Unknown"), 9, _hoisted_13),
                        _createElementVNode("span", _hoisted_14, [
                          _createTextVNode(_toDisplayString(_ctx.timeSince(Number(_ctx.comment.created))) + " ago ", 1),
                          (_ctx.comment.edited)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_15, "  (Edited " + _toDisplayString(_ctx.timeSince(Number(_ctx.comment.edited))) + " ago)  ", 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      (!_ctx.comment.deleted)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createElementVNode("div", {
                              innerHTML: _ctx.comment?.content,
                              class: "comment-body-text rendered-html"
                            }, null, 8, _hoisted_17)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[9] || (_cache[9] = [
                            _createElementVNode("div", {
                              innerHTML: '<p>[ Deleted ]</p>',
                              class: "comment-body-text rendered-html"
                            }, null, -1)
                          ]))),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "btn btn-sm btn-outline-secondary btn-outline-no-border color h-bg-color h-text-light mt-2",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.likeComment && _ctx.likeComment(...args)))
                          }, [
                            _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fa-regular fa-thumbs-up" }, null, -1)),
                            _cache[11] || (_cache[11] = _createTextVNode(" Like ")),
                            (_ctx.likeCount > 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_21, "(" + _toDisplayString(_ctx.likeCount) + ")", 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("button", {
                            type: "button",
                            class: "btn btn-sm btn-outline-secondary btn-outline-no-border color h-bg-color h-text-light mt-2",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openReply()))
                          }, _cache[12] || (_cache[12] = [
                            _createElementVNode("i", { class: "fa-regular fa-comment" }, null, -1),
                            _createTextVNode(" Reply ")
                          ]))
                        ])
                      ]),
                      _cache[16] || (_cache[16] = _createElementVNode("a", {
                        class: "comment-reply-link",
                        href: "#",
                        id: "editlink2",
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false"
                      }, [
                        _createElementVNode("i", { class: "bi bi-three-dots" })
                      ], -1)),
                      _createElementVNode("div", _hoisted_22, [
                        (_ctx.isOwnComment)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: "dropdown-item",
                              href: '/community/submit?edit=' + _ctx.comment.postId + '&comment=' + _ctx.comment.commentId
                            }, _cache[13] || (_cache[13] = [
                              _createElementVNode("i", { class: "bi bi-pencil me-1" }, null, -1),
                              _createTextVNode(" Edit ")
                            ]), 8, _hoisted_23))
                          : _createCommentVNode("", true),
                        (_ctx.isOwnComment)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              class: "dropdown-item",
                              href: "#",
                              "data-bs-toggle": "modal",
                              "data-bs-target": '#' + _ctx.deleteCommentModalId
                            }, _cache[14] || (_cache[14] = [
                              _createElementVNode("i", { class: "bi bi-trash me-1" }, null, -1),
                              _createTextVNode(" Delete ")
                            ]), 8, _hoisted_24))
                          : _createCommentVNode("", true),
                        (!_ctx.isOwnComment)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 2,
                              class: "dropdown-item",
                              href: "#",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.flagContent('Comment ' + _ctx.comment.commentId)))
                            }, _cache[15] || (_cache[15] = [
                              _createElementVNode("i", { class: "bi bi-flag me-1" }, null, -1),
                              _createTextVNode(" Flag ")
                            ])))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "clear" }, null, -1))
                  ]),
                  (_ctx.showEditor)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("div", null, [
                          _createVNode(_component_TextEditor, { ref: "commentPostTextEditor" }, null, 512),
                          _createElementVNode("button", {
                            type: "submit",
                            class: "button m-0 bg-color text-light h-op-08 me-2 mt-2",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.createComment(_ctx.comment?.commentId)))
                          }, " Submit "),
                          _createElementVNode("button", {
                            class: "button button-border border-color m-0 color h-bg-color h-text-light mt-2",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeEditor()))
                          }, " Close "),
                          (_ctx.isShowInvalidComment)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, " Comment must be between 5 - 2000 characters. "))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.replyComments, (reply) => {
        return (_openBlock(), _createElementBlock("ul", {
          key: reply.commentId,
          style: {"margin-bottom":"0 !important"}
        }, [
          _createVNode(_component_Comment, {
            postComments: _ctx.postComments,
            postCommentLikes: _ctx.postCommentLikes,
            postId: _ctx.postId,
            commentId: reply.commentId,
            commentLevel: _ctx.commentLevel + 1
          }, null, 8, ["postComments", "postCommentLikes", "postId", "commentId", "commentLevel"])
        ]))
      }), 128))
    ]),
    (_ctx.commentLevel === 4 && _ctx.isFirstSameLevelComment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
          _createElementVNode("a", {
            style: {"cursor":"pointer"},
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.emitShowMoreCommentsEvent()))
          }, _cache[18] || (_cache[18] = [
            _createElementVNode("i", { class: "bi bi-plus-circle" }, null, -1),
            _createTextVNode(" More Comments ")
          ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "modal fade",
      id: _ctx.deleteCommentModalId,
      tabindex: "-1",
      "aria-labelledby": "deleteCommentModalLabel",
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _cache[19] || (_cache[19] = _createElementVNode("h3", { class: "mb-2" }, " Delete Comment ", -1)),
                _cache[20] || (_cache[20] = _createElementVNode("p", { class: "mb-4 fw-normal text-smaller text-black-50" }, " Are you sure you want to delete this comment? ", -1)),
                _createElementVNode("button", {
                  type: "button",
                  class: "button button-large m-0 bg-color text-light h-op-08",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.deleteComment && _ctx.deleteComment(...args))),
                  "data-bs-dismiss": "modal"
                }, "Delete")
              ])
            ])
          ])
        ])
      ])
    ], 8, _hoisted_28)
  ], 64))
}