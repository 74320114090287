import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "py-4 page-title-center" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "page-title-row" }
const _hoisted_7 = { "aria-label": "breadcrumb" }
const _hoisted_8 = { class: "breadcrumb mt-4" }
const _hoisted_9 = { class: "breadcrumb-item" }
const _hoisted_10 = { class: "breadcrumb-item" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { id: "content" }
const _hoisted_13 = {
  key: 0,
  class: "container"
}
const _hoisted_14 = { class: "container" }
const _hoisted_15 = { class: "topic-post p-4 p-md-5 border-top border-width-5 border-color" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "row justify-content-between align-items-center mb-4" }
const _hoisted_18 = { class: "col-12" }
const _hoisted_19 = {
  class: "mb-0 fw-bold",
  style: {"word-wrap":"break-word"}
}
const _hoisted_20 = { class: "row g-0 justify-content-between align-items-center border-top border-bottom py-3 mb-4" }
const _hoisted_21 = { class: "col-9" }
const _hoisted_22 = { class: "d-flex align-items-center" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "mb-0 h6 fw-semibold" }
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "text-muted mb-0 fw-normal" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "col-auto" }
const _hoisted_29 = {
  class: "dropdown-menu dropdown-menu-links rounded shadow-sm dropdown-menu-end py-0 m-0",
  "aria-labelledby": "editlink2"
}
const _hoisted_30 = ["href"]
const _hoisted_31 = {
  key: 1,
  class: "dropdown-item",
  href: "#",
  "data-bs-toggle": "modal",
  "data-bs-target": "#deletePostModal"
}
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = ["href"]
const _hoisted_34 = ["src"]
const _hoisted_35 = { class: "lh-sm small mt-3 op-06" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_38 = ["disabled"]
const _hoisted_39 = { key: 1 }
const _hoisted_40 = {
  key: 0,
  class: "my-2 text-danger"
}
const _hoisted_41 = { id: "content" }
const _hoisted_42 = { class: "mt-3" }
const _hoisted_43 = { class: "container" }
const _hoisted_44 = { class: "mb-3" }
const _hoisted_45 = { class: "mb-0 fw-bold" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { key: 0 }
const _hoisted_48 = { key: 1 }
const _hoisted_49 = {
  key: 2,
  class: "my-5 text-center"
}
const _hoisted_50 = { id: "content" }
const _hoisted_51 = { class: "content-wrap" }
const _hoisted_52 = {
  key: 0,
  class: "container mw-md mb-5"
}
const _hoisted_53 = { class: "container mw-md" }
const _hoisted_54 = { class: "container" }
const _hoisted_55 = {
  key: 0,
  class: "row justify-content-center text-center mb-5"
}
const _hoisted_56 = { class: "mw-md mx-auto" }
const _hoisted_57 = {
  key: 0,
  class: "list-unstyled mb-4"
}
const _hoisted_58 = {
  key: 1,
  class: "list-unstyled mb-4"
}
const _hoisted_59 = {
  key: 1,
  class: "forum-content-container"
}
const _hoisted_60 = {
  class: "page-title page-title-parallax parallax scroll-detect py-lg-6 scroll-detect-inview text-pop-image",
  style: {"--cnvs-scroll-height":"395","--cnvs-scroll-percent":"54.493087557603694","--cnvs-scroll-ratio":"1","--cnvs-scroll-start":"1","--cnvs-scroll-end":"0"}
}
const _hoisted_61 = ["src"]
const _hoisted_62 = { class: "container" }
const _hoisted_63 = { class: "page-title-row" }
const _hoisted_64 = { class: "page-title-content mw-xs mx-auto" }
const _hoisted_65 = { class: "h1 mb-3 text-center text-pop-image-text" }
const _hoisted_66 = { class: "color text-larger text-center mt-4 mb-3 text-pop-image-text" }
const _hoisted_67 = { class: "input-group input-group-lg mt-4 mb-4 mw-sm mx-auto" }
const _hoisted_68 = { id: "content" }
const _hoisted_69 = { class: "content-wrap pt-4" }
const _hoisted_70 = { class: "container mw-md" }
const _hoisted_71 = { class: "d-inline-flex w-100 my-2" }
const _hoisted_72 = { class: "d-flex p-0 m-0 w-100 justify-content-between" }
const _hoisted_73 = { class: "forum-home-toolbar" }
const _hoisted_74 = ["selected"]
const _hoisted_75 = ["selected"]
const _hoisted_76 = ["selected"]
const _hoisted_77 = ["selected"]
const _hoisted_78 = ["selected"]
const _hoisted_79 = {
  key: 0,
  class: "forum-home-toolbar"
}
const _hoisted_80 = ["value"]
const _hoisted_81 = {
  key: 1,
  class: "forum-home-toolbar"
}
const _hoisted_82 = ["value"]
const _hoisted_83 = {
  key: 0,
  class: "d-inline-flex w-100 mt-2 mb-4"
}
const _hoisted_84 = {
  key: 0,
  class: "me-2"
}
const _hoisted_85 = ["href"]
const _hoisted_86 = { class: "badge bg-secondary text-white" }
const _hoisted_87 = {
  key: 1,
  class: "me-2"
}
const _hoisted_88 = ["href"]
const _hoisted_89 = { class: "badge bg-secondary text-white" }
const _hoisted_90 = { class: "mw-md mx-auto" }
const _hoisted_91 = {
  key: 0,
  class: "list-unstyled mb-4"
}
const _hoisted_92 = {
  key: 1,
  class: "list-unstyled mb-4"
}
const _hoisted_93 = { class: "pagination justify-content-center" }
const _hoisted_94 = { key: 0 }
const _hoisted_95 = {
  key: 1,
  class: "px-5 py-5"
}
const _hoisted_96 = {
  key: 2,
  class: "my-5 text-center"
}
const _hoisted_97 = {
  class: "modal fade",
  id: "deletePostModal",
  tabindex: "-1",
  "aria-labelledby": "deletePostModalLabel",
  "aria-hidden": "true"
}
const _hoisted_98 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_99 = { class: "modal-content" }
const _hoisted_100 = { class: "modal-body" }
const _hoisted_101 = { class: "row p-4 bg-white d-flex align-items-center justify-content-center rounded" }
const _hoisted_102 = { class: "col-md-12 p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadingPost = _resolveComponent("LoadingPost")!
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Comment = _resolveComponent("Comment")!
  const _component_LoadingComment = _resolveComponent("LoadingComment")!
  const _component_PostOutline = _resolveComponent("PostOutline")!
  const _component_LoadingPostOutline = _resolveComponent("LoadingPostOutline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showPostView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("section", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("nav", _hoisted_7, [
                      _createElementVNode("ol", _hoisted_8, [
                        _createElementVNode("li", _hoisted_9, [
                          _createVNode(_component_router_link, {
                            class: "alt-color",
                            to: "/community/posts"
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(" Community ")
                            ])),
                            _: 1
                          })
                        ]),
                        _createElementVNode("li", _hoisted_10, [
                          _createElementVNode("a", {
                            class: "alt-color",
                            href: '/community/posts?category=' + _ctx.postDetails?.category
                          }, _toDisplayString(_ctx.postDetails?.category), 9, _hoisted_11)
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("section", _hoisted_12, [
                (_ctx.isLoadPostApiCallInProgress)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_LoadingPost)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    (!_ctx.isLoadPostApiCallInProgress)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("h2", _hoisted_19, _toDisplayString(_ctx.postDetails?.title), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("img", {
                                  alt: "Image",
                                  src: _ctx.postDetails?.user?.userThumbnail ? _ctx.assetHost + '/' + _ctx.postDetails?.user?.userThumbnail : '/img/profile_not_found.png',
                                  onerror: "this.onerror=null; this.src='/img/profile_not_found.png'",
                                  class: "rounded-circle me-2",
                                  width: "50",
                                  height: "50"
                                }, null, 8, _hoisted_23),
                                _createElementVNode("div", null, [
                                  _createElementVNode("h5", _hoisted_24, [
                                    _createElementVNode("a", {
                                      class: "text-dark",
                                      href: '/user/' + _ctx.postDetails?.userId
                                    }, _toDisplayString(_ctx.postDetails?.user?.username ?? 'Unknown'), 9, _hoisted_25)
                                  ]),
                                  _createElementVNode("small", _hoisted_26, [
                                    _createTextVNode(_toDisplayString(_ctx.timeSince(Number(_ctx.postDetails?.created))) + " ago ", 1),
                                    (_ctx.postDetails?.edited)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, "  (Edited " + _toDisplayString(_ctx.timeSince(Number(_ctx.postDetails?.edited))) + " ago)  ", 1))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_28, [
                              _cache[18] || (_cache[18] = _createElementVNode("a", {
                                href: "#",
                                id: "editlink2",
                                "data-bs-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false"
                              }, [
                                _createElementVNode("i", { class: "uil uil-ellipsis-h icon-2x alt-color" })
                              ], -1)),
                              _createElementVNode("div", _hoisted_29, [
                                (_ctx.isOwnPost)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      class: "dropdown-item",
                                      href: '/community/submit?edit=' + _ctx.postDetails?.postId
                                    }, _cache[15] || (_cache[15] = [
                                      _createElementVNode("i", { class: "bi bi-pencil me-1" }, null, -1),
                                      _createTextVNode(" Edit ")
                                    ]), 8, _hoisted_30))
                                  : _createCommentVNode("", true),
                                (_ctx.isOwnPost)
                                  ? (_openBlock(), _createElementBlock("a", _hoisted_31, _cache[16] || (_cache[16] = [
                                      _createElementVNode("i", { class: "bi bi-trash me-1" }, null, -1),
                                      _createTextVNode(" Delete ")
                                    ])))
                                  : _createCommentVNode("", true),
                                _createElementVNode("a", {
                                  class: "dropdown-item",
                                  href: "#",
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.flagContent('Post ' + _ctx.postId)))
                                }, _cache[17] || (_cache[17] = [
                                  _createElementVNode("i", { class: "bi bi-flag me-1" }, null, -1),
                                  _createTextVNode(" Flag ")
                                ]))
                              ])
                            ])
                          ]),
                          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "clear" }, null, -1)),
                          _createElementVNode("div", {
                            class: "rendered-html mb-4",
                            style: {"word-wrap":"break-word"},
                            innerHTML: _ctx.postDetails?.content
                          }, null, 8, _hoisted_32)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["row", { 'd-none': !_ctx.postDetails?.attachments || _ctx.isLoadPostApiCallInProgress }])
                    }, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4], (index) => {
                        return _createElementVNode("div", {
                          class: "col-lg-2 col-md-3 col-4 lightbox-preview-row",
                          key: index
                        }, [
                          _createElementVNode("a", {
                            href: _ctx.assetHost + '/' + _ctx.postDetails?.attachments[index],
                            "data-lightbox": "image"
                          }, [
                            ( _ctx.postDetails?.attachments[index] !== undefined)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: _ctx.assetHost + '/' + _ctx.postDetails?.attachments[index],
                                  alt: "Image",
                                  class: "mb-1 rounded-1 lightbox-preview-image rounded-circle"
                                }, null, 8, _hoisted_34))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_33),
                          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "d-flex align-items-center justify-content-between px-2" }, null, -1))
                        ])
                      }), 64))
                    ], 2),
                    _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.formatNumber(_ctx.postDetails?.views)) + " Views ", 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2 me-2",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.likePost && _ctx.likePost(...args)))
                      }, [
                        _cache[21] || (_cache[21] = _createElementVNode("i", { class: "bi bi-hand-thumbs-up" }, null, -1)),
                        _cache[22] || (_cache[22] = _createTextVNode("Like ")),
                        (_ctx.postLikes.length > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_36, "(" + _toDisplayString(_ctx.postLikes.length) + ")", 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        class: "button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleShowEditor(true)))
                      }, _cache[23] || (_cache[23] = [
                        _createElementVNode("i", { class: "bi bi-reply" }, null, -1),
                        _createTextVNode("Reply ")
                      ]))
                    ]),
                    (_ctx.showEditor)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                          _createElementVNode("form", {
                            onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.createComment()), ["prevent"])),
                            class: "my-4"
                          }, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_TextEditor, {
                                ref: "commentPostTextEditor",
                                enableImage: false
                              }, null, 512),
                              _createElementVNode("button", {
                                type: "submit",
                                disabled: _ctx.isSubmitCommentApiCallInProgress,
                                class: "button button-small m-0 bg-color text-light h-op-08 me-2 mt-2"
                              }, [
                                (_ctx.isSubmitCommentApiCallInProgress)
                                  ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_39, "Submit"))
                              ], 8, _hoisted_38),
                              _createElementVNode("button", {
                                class: "button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2 me-2",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleShowEditor(false)))
                              }, " Close "),
                              (_ctx.isShowInvalidComment)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_40, " Comment must be between 5 - 2000 characters. "))
                                : _createCommentVNode("", true)
                            ])
                          ], 32)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("section", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", _hoisted_44, [
                      _createElementVNode("h2", _hoisted_45, [
                        _cache[24] || (_cache[24] = _createTextVNode(" Comments ")),
                        (!_ctx.isLoadPostApiCallInProgress)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_46, "(" + _toDisplayString(_ctx.formatNumber(_ctx.postComments.length)) + ")", 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _cache[26] || (_cache[26] = _createElementVNode("hr", { class: "mb-4" }, null, -1)),
                    (!_ctx.isLoadPostApiCallInProgress)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parentComments, (comment) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: comment.commentId
                            }, [
                              _createVNode(_component_Comment, {
                                postComments: _ctx.postComments,
                                postCommentLikes: _ctx.postCommentLikes,
                                postId: _ctx.postId,
                                commentId: comment.commentId,
                                commentLevel: 1,
                                isShowCommentsMode: _ctx.isShowCommentsMode
                              }, null, 8, ["postComments", "postCommentLikes", "postId", "commentId", "isShowCommentsMode"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.isLoadPostApiCallInProgress)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3], (index) => {
                            return _createElementVNode("div", { key: index }, [
                              _createVNode(_component_LoadingComment, { class: "mb-3" })
                            ])
                          }), 64))
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isLoadPostApiCallInProgress && _ctx.parentComments.length === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_49, _cache[25] || (_cache[25] = [
                          _createElementVNode("div", { class: "feature-box fbox-effect flex-column" }, [
                            _createElementVNode("div", { class: "fbox-content" }, [
                              _createElementVNode("h3", null, "No Comments"),
                              _createElementVNode("p", null, "Be the first to share what you think")
                            ])
                          ], -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("section", _hoisted_50, [
                _createElementVNode("div", _hoisted_51, [
                  (!_ctx.isLoggedIn)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_52, _cache[27] || (_cache[27] = [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", { class: "signup-box" }, [
                            _createElementVNode("h2", null, "Create an account or sign in to comment"),
                            _createElementVNode("p", null, "You need to be a member in order to leave a comment"),
                            _createElementVNode("button", {
                              class: "signup-btn",
                              onclick: "window.location.href='/signIn';"
                            }, " SIGN UP / LOGIN ")
                          ])
                        ], -1)
                      ])))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_53, [
                    _createElementVNode("div", _hoisted_54, [
                      (!(!_ctx.isLoadRelatedPostsApiCallInProgress && _ctx.relatedPosts.length == 0))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_55, _cache[28] || (_cache[28] = [
                            _createElementVNode("div", { class: "col-xl-6 col-lg-8" }, [
                              _createElementVNode("h2", { class: "mb-0 fw-bold" }, " Related Posts ")
                            ], -1)
                          ])))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_56, [
                        (!_ctx.isLoadRelatedPostsApiCallInProgress && _ctx.relatedPosts.length > 0)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_57, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedPosts.slice(0, 3), (post) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: post.postId
                                }, [
                                  _createVNode(_component_PostOutline, { post: post }, null, 8, ["post"])
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.isLoadRelatedPostsApiCallInProgress)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_58, [
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3], (index) => {
                                return _createElementVNode("li", { key: index }, [
                                  _createVNode(_component_LoadingPostOutline)
                                ])
                              }), 64))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_59, [
              _createElementVNode("section", _hoisted_60, [
                _createElementVNode("img", {
                  src: _ctx.appConfigs.assets.communityBannerImage,
                  class: "parallax-bg"
                }, null, 8, _hoisted_61),
                _createElementVNode("div", _hoisted_62, [
                  _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("div", _hoisted_64, [
                      _createElementVNode("h2", _hoisted_65, _toDisplayString(_ctx.appConfigs.layout.community.header), 1),
                      _createElementVNode("p", _hoisted_66, _toDisplayString(_ctx.appConfigs.layout.community.headerCaptionAlt), 1),
                      _createElementVNode("form", {
                        onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.searchCommunity()), ["prevent"]))
                      }, [
                        _createElementVNode("div", _hoisted_67, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control rounded-start border-0 fw-semibold",
                            type: "search",
                            placeholder: "Search...",
                            "aria-label": "Search",
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchText) = $event))
                          }, null, 512), [
                            [_vModelText, _ctx.searchText]
                          ]),
                          _cache[29] || (_cache[29] = _createElementVNode("button", {
                            class: "btn border-0 bg-white rounded-end h-bg-alt",
                            type: "submit"
                          }, [
                            _createElementVNode("i", { class: "uil uil-search" })
                          ], -1))
                        ])
                      ], 32),
                      _cache[30] || (_cache[30] = _createStaticVNode("<div class=\"text-center\" data-v-1a1eb580><a href=\"/community/submit\" class=\"button border border-width-2 bg-alt py-2 rounded-1 fw-medium text-transform-none ls-0 ms-0 ms-sm-1 h-op-09\" data-v-1a1eb580><i class=\"bi-file-earmark-plus\" data-v-1a1eb580></i>Create Topic </a><a href=\"/community\" class=\"button border border-width-2 bg-alt py-2 rounded-1 fw-medium text-transform-none ls-0 ms-0 ms-sm-1 h-op-09\" data-v-1a1eb580><i class=\"bi bi-house\" data-v-1a1eb580></i>Home </a></div>", 1))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("section", _hoisted_68, [
                _createElementVNode("div", _hoisted_69, [
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("div", _hoisted_71, [
                      _createElementVNode("ul", _hoisted_72, [
                        _createElementVNode("li", _hoisted_73, [
                          _cache[31] || (_cache[31] = _createElementVNode("label", {
                            for: "topSelect",
                            class: "visually-hidden"
                          }, "Select Sort Option", -1)),
                          _withDirectives(_createElementVNode("select", {
                            class: "form-select custom-select-dropdown",
                            id: "topSelect",
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sortFilter) = $event)),
                            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateSortFilter($event.target.value)))
                          }, [
                            _createElementVNode("option", {
                              value: "Latest",
                              selected: _ctx.sortFilter === 'Latest'
                            }, "Latest", 8, _hoisted_74),
                            _createElementVNode("option", {
                              value: "Top (Week)",
                              selected: _ctx.sortFilter === 'Top (Week)'
                            }, "Top (Week)", 8, _hoisted_75),
                            _createElementVNode("option", {
                              value: "Top (Month)",
                              selected: _ctx.sortFilter === 'Top (Month)'
                            }, "Top (Month)", 8, _hoisted_76),
                            _createElementVNode("option", {
                              value: "Top (Year)",
                              selected: _ctx.sortFilter === 'Top (Year)'
                            }, "Top (Year)", 8, _hoisted_77),
                            _createElementVNode("option", {
                              value: "Top (All)",
                              selected: _ctx.sortFilter === 'Top (All)'
                            }, "Top (All)", 8, _hoisted_78)
                          ], 544), [
                            [_vModelSelect, _ctx.sortFilter]
                          ])
                        ]),
                        (_ctx.categoryFilter)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_79, [
                              _cache[32] || (_cache[32] = _createElementVNode("label", {
                                for: "categorySelect",
                                class: "visually-hidden"
                              }, "Select Category", -1)),
                              _withDirectives(_createElementVNode("select", {
                                class: "form-select custom-select-dropdown",
                                id: "categorySelect",
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.categoryFilter) = $event)),
                                onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateCategoryFilter($event.target.value)))
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appConfigs.layout.community.category, (tag) => {
                                  return (_openBlock(), _createElementBlock("option", {
                                    key: tag.name,
                                    value: tag.name
                                  }, _toDisplayString(tag.name), 9, _hoisted_80))
                                }), 128))
                              ], 544), [
                                [_vModelSelect, _ctx.categoryFilter]
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("li", _hoisted_81, [
                              _cache[34] || (_cache[34] = _createElementVNode("label", {
                                for: "categorySelect",
                                class: "visually-hidden"
                              }, "Select Category", -1)),
                              _createElementVNode("select", {
                                class: "form-select custom-select-dropdown",
                                id: "categorySelect",
                                onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateCategoryFilter($event.target.value)))
                              }, [
                                _cache[33] || (_cache[33] = _createElementVNode("option", {
                                  value: "",
                                  class: "selected hidden disabled"
                                }, "Select Category", -1)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appConfigs.layout.community.category, (tag) => {
                                  return (_openBlock(), _createElementBlock("option", {
                                    key: tag.name,
                                    value: tag.name
                                  }, _toDisplayString(tag.name), 9, _hoisted_82))
                                }), 128))
                              ], 32)
                            ]))
                      ])
                    ]),
                    (_ctx.categoryFilter || _ctx.searchFilter)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                          (_ctx.categoryFilter)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_84, [
                                _createElementVNode("a", {
                                  href: _ctx.buildPostQueryString(_ctx.searchFilter, null, _ctx.sortFilter)
                                }, [
                                  _createElementVNode("span", _hoisted_86, [
                                    _cache[35] || (_cache[35] = _createElementVNode("i", { class: "fa-solid fa-tag" }, null, -1)),
                                    _createTextVNode("  " + _toDisplayString(_ctx.categoryFilter) + "   ", 1),
                                    _cache[36] || (_cache[36] = _createElementVNode("i", { class: "fas fa-times" }, null, -1))
                                  ])
                                ], 8, _hoisted_85)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.searchFilter)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_87, [
                                _createElementVNode("a", {
                                  href: _ctx.buildPostQueryString(null, _ctx.categoryFilter, _ctx.sortFilter)
                                }, [
                                  _createElementVNode("span", _hoisted_89, [
                                    _cache[37] || (_cache[37] = _createElementVNode("i", { class: "fa-solid fa-search" }, null, -1)),
                                    _createTextVNode("  " + _toDisplayString(_ctx.searchFilter) + "   ", 1),
                                    _cache[38] || (_cache[38] = _createElementVNode("i", { class: "fas fa-times" }, null, -1))
                                  ])
                                ], 8, _hoisted_88)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_90, [
                      (!_ctx.isInitialLoadApiCallInProgress)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_91, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: post.postId
                              }, [
                                _createVNode(_component_PostOutline, { post: post }, null, 8, ["post"])
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isInitialLoadApiCallInProgress)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_92, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (index) => {
                              return _createElementVNode("li", { key: index }, [
                                _createVNode(_component_LoadingPostOutline)
                              ])
                            }), 64))
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("ul", _hoisted_93, [
                        (!_ctx.isLoadMoreApiCallInProgress && _ctx.posts?.length !== 0 && (_ctx.lastStartKey || _ctx.offset != -1))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2",
                                onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.loadMorePosts && _ctx.loadMorePosts(...args)))
                              }, " Load More ")
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.isLoadMoreApiCallInProgress && !_ctx.isInitialLoadApiCallInProgress)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
                              _createVNode(_component_LoadingSpinner, { class: "my-3" })
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      (!_ctx.isInitialLoadApiCallInProgress && _ctx.posts.length === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_96, _cache[39] || (_cache[39] = [
                            _createElementVNode("div", { class: "feature-box fbox-effect flex-column" }, [
                              _createElementVNode("div", { class: "fbox-content" }, [
                                _createElementVNode("h3", null, "No Posts"),
                                _createElementVNode("p", null, "Be the first to ask a question!")
                              ])
                            ], -1)
                          ])))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ]))
      ])
    ]),
    _cache[43] || (_cache[43] = _createStaticVNode("<div class=\"section bg-transparent my-0 py-0\" data-v-1a1eb580><div class=\"container-fluid py-6\" data-v-1a1eb580><div class=\"row justify-content-center mx-auto mw-md gx-5\" data-v-1a1eb580><div class=\"col\" data-v-1a1eb580><p class=\"h6 mb-0\" data-v-1a1eb580> Opinions expressed on this forum reflect the views of our community and do not constitute professional advice. For further information, please refer to our <a href=\"/community/guidelines\" data-v-1a1eb580>Community Guidelines.</a> Have a quesiton? Need to correct some bad information? Have questions about our community? <a href=\"/about#contact\" data-v-1a1eb580>Contact Us</a></p></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_97, [
      _createElementVNode("div", _hoisted_98, [
        _createElementVNode("div", _hoisted_99, [
          _cache[42] || (_cache[42] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_100, [
            _createElementVNode("div", _hoisted_101, [
              _createElementVNode("div", _hoisted_102, [
                _cache[40] || (_cache[40] = _createElementVNode("h3", { class: "mb-2" }, " Delete Post ", -1)),
                _cache[41] || (_cache[41] = _createElementVNode("p", { class: "mb-4 fw-normal text-smaller text-black-50" }, " Are you sure you want to delete this post? ", -1)),
                _createElementVNode("button", {
                  type: "button",
                  class: "button button-large m-0 bg-color text-light h-op-08",
                  onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.deletePost && _ctx.deletePost(...args))),
                  "data-bs-dismiss": "modal"
                }, "Delete")
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}