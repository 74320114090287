<template>
   <div id="content">
      <div>
         <section id="slider" class="slider-element">
            <div class="position-absolute end-0 bootom-0 w-100 h-100 hero-img" style="background: url('/demos/mj/cc_home_banner.png') no-repeat bottom / auto 100%; min-height: 500px;"></div>
            <div class="container py-5 py-lg-6">
               <div class="row align-items-center justify-content-between">
                  <div class="col-xl-6 col-lg-7 pt-lg-5 pb-lg-6 ps-5">
                     <div class="position-relative z-1">
                        <div class="fs-6 text-uppercase ls-3 mb-4"></div>
                        <h2 class="mb-0 display-4 lh-1">Crossword Puzzles<br>
                           <span class="font-secondary fw-semibold fst-italic text-lowercase color display-2">Live</span><span>.</span>
                        </h2>
                        <p
                           class="fs-6 mt-3 fw-normal title-text"
                        >
                           Join our Live Crossword Arena. Compete against other players to solve 
                           5x5 crosswords and climb the leaderboard.
                        </p>
                     </div>
                     <div class="mt-4 mb-5">
                        <a href='/crossword/live'>
                        <button type="button" class="button button-small m-0 bg-color text-light h-op-08 me-2 mt-2">Play Now</button>
                        </a>
                        <a href='/about'>
                        <button
                           type="button"
                           class="button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2 ms-2"
                           style="background-color: white;"
                        >About</button>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-5">
                  </div>
               </div>
            </div>
         </section>
         <div class="section bg-transparent my-0 my-lg-5 pb-0 pb-lg-5">
            <div class="container py-3">
               <div class="row gy-5">
                  <div class="col-sm-9">
                     <h4 class="display-4 mb-0 fw-normal text-black">
                        Race Against Other Players in Our Live Arena
                     </h4>
                  </div>
                  <div class="col-md-4">
                     <p class="mb-5">
                        <strong class="font-secondary">There's an unlimited supply of new 5x5 crosswords</strong> and games happening all the time. 
                        Whether you're looking for a quick break or a longer session, there's always a new game waiting for you.
                     </p>
                     <a href="/post/How%20to%20Play" target="_blank" class="button button-border button-dark rounded-pill ms-0">How To Play<i class="bi-arrow-right ms-2"></i></a>
                  </div>
                  <div class="col-md-8">
                     <img src="/demos/mj/cc_gameplay.png" class="rounded-6" alt="...">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="section bg-color bg-opacity-10 my-0">
      <div class="container py-5">
         <div class="row justify-content-center mb-4">
            <div class="col-md-6 text-center">
               <p class="color text-uppercase ls-3 small mb-3">Blog</p>
               <h3 class="display-5 mb-5">Our Latest Posts</h3>
            </div>
         </div>
         <div class="row col-mb-50 posts-md">
            <div class="col-lg-4 entry mb-0" v-for="post in posts.slice(0, 3)" :key=post.id>
               <ArticlePreview :post="post" />
            </div>
         </div>
      </div>
   </div>
   <div class="section bg-transparent my-0 py-0">
     <div class="container-fluid py-6">
        <div class="row justify-content-center mx-auto mw-md gx-5">
           <div class="col">
              <p class="h6 mb-0">
               This site and its community are designed for entertainment and engagement. While we strive for 
               accuracy in our puzzles and content, occasional errors may occur, and community contributions 
               reflect individual opinions, not the views of the site.  If you have inquiries, wish to rectify misinformation, or 
               seek clarification about our community, feel free to <a href="/about#contact">Contact Us</a>.
              </p>
           </div>
        </div>
     </div>
  </div>
</template>
 
 <script lang="ts">
 import { Vue, Options } from 'vue-class-component';
 import { Post } from '@/models/Cms';
 import { loadNewContent } from '@/utils/cms';
 import { convertTimeToDate } from '@/utils/time';
 import { APP_CONFIGS } from '@/main';
 import { FrameControl } from '@/models/FrameControl';
 import ArticlePreview from "@/components/post/ArticlePreview.vue";
 
 
 @Options({
     components: {
         ArticlePreview,
     }
 })
 export default class CCHome extends Vue {
 
     posts: Post[] = [];
 
     public async mounted(): Promise<void> {
         this.posts = await loadNewContent();
     }
 
     public convertTimeToDate(dateString: string): string {
         return convertTimeToDate(dateString);
     }
 
     get appConfigs(): FrameControl {
         return APP_CONFIGS;
     }
 
 }
 </script>
 
<style scoped>
.block-ellipsis {
   display: -webkit-box;
   max-width: 100%;
   margin: 0 auto;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   min-height: 75px;
}
.hero-img {
	opacity: .3;
}
.middle-section {
    position: relative;
    width: 100%;
    background-color: var(--cnvs-section-bg);
}
.title-text {
   text-shadow: 0.1px 0.1px 0 black;
   -webkit-text-shadow: 0.1px 0.1px 0 black;
}
</style>