<template>
  <div id="wrapper">
     <Header />
     <div class="position-fixed top-0 end-0 p-3" style="z-index: 99999">
        <div id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
           <div class="toast-body">
            <i class="bi bi-exclamation-triangle-fill"></i> {{ errorMessage }}
           </div>
        </div>
     </div>
     <div class="bg-body">
        <router-view></router-view>
     </div>
     <div class="modal fade" id="flagContentModal" tabindex="-1" aria-labelledby="flagContentModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
           <div class="modal-content">
              <div class="modal-header">
                 <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                 <div class="row p-4 bg-white d-flex align-items-center justify-content-center rounded">
                    <div class="col-md-12 p-4">
                       <h3 class="mb-2">
                          Flag Content
                       </h3>
                       <p class="mb-4 fw-normal text-smaller text-black-50">
                          Thanks for reporting things that look suspicious or that break the rules. 
                          Let us know what's happening, and we'll look into it. 
                       </p>
                       <p class="mb-4 fw-normal text-smaller text-black-50">
                          Please provide us with as much detail as possible regarding the content you are reporting. 
                          Your input will help us investigate and take appropriate action accordingly.
                       </p>
                       <div class="form-group">
                          <textarea
                             class="form-control mt-3 mb-1"
                             placeholder="Flag Content"
                             v-model="reportContent"
                             rows="4"
                             @keyup.stop
                             ></textarea>
                       </div>
                       <button type="button" class="button button-large m-0 bg-color text-light h-op-08" data-bs-dismiss="modal" @click="submitReport">Submit</button>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
     <Footer />
  </div>
  <div v-if="isBetaDeploy">
   <BetaOverlay />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import Header from '@/components/header/Header.vue';
import Footer from '@/components/footer/Footer.vue';
import BetaOverlay from '@/components/misc/BetaOverlay.vue';
import store, { VuexModuleNamespaces, DataStore } from './store';
import { Amplify } from 'aws-amplify';
import { APP_CONFIGS } from './main';
import { FrameControl } from './models/FrameControl';
import { updateUser } from './utils/user';
import { createReport } from './service/common';
import AboutView from './views/about/AboutView.vue';

// eslint-disable-next-line
const bootstrap = require("bootstrap");

@Options({
  components: {
    Header,
    Footer,
    AboutView,
    BetaOverlay
  }
})
export default class App extends Vue {

  reportContent = "";
  reportContentType = "";
  isBetaDeploy = false;

  public created(): void {    
    window.addEventListener('xFrame:error', async () => {
      const toast = new bootstrap.Toast(document.getElementById('liveToast'), {});
      toast.show();
    }, false);

    window.addEventListener('xFrame:flagContent', (event: Event) => {
      this.reportContentType = (event as CustomEvent).detail;
      const modal = new bootstrap.Modal(document.getElementById('flagContentModal'), {});
      modal.show();
    }, false);

  }
  
  public async mounted(): Promise<void> {
    // Check if need overaly
    this.checkBetaDeploy()

    // Fetch configs from environment
    store.commit(
      `${VuexModuleNamespaces.App}/${DataStore.mutations.updateConfigs.name}`,
      APP_CONFIGS
    );

    // Set clients
    const configs: FrameControl = store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getConfigs.name}`];
    const userPoolId = configs.userManagement.cognito.userPoolId;
    const audience =configs.userManagement.cognito.audience;
    const oauth = configs.userManagement.oauth

    // Setup amplify
    Amplify.configure({
        Auth: {
            userPoolId: userPoolId,
            userPoolWebClientId: audience,
        },
        oauth: oauth
    });

    // Update user
    await updateUser();
  }


  public async submitReport(): Promise<void> {
      try {
          await createReport({
              "content": this.reportContent,
              "context": this.reportContentType,
          });
      } catch(err) {
          store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
      } finally {
        this.reportContent = "";
        this.reportContentType = "";
      }
  }

  public checkBetaDeploy(): void {
   const hostname = window.location.hostname;
      if (hostname.includes("beta")) {
        this.isBetaDeploy = true;
      }
  }


  get errorMessage(): string {
    return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getErrorMessage.name}`];
  }

}
</script>

<style lang="scss">
.modal-header {
   border-bottom: 0;
}
.toast-header {
   border-bottom: 0;
}
</style>
