import axios, { AxiosResponse } from "axios";
import { PostResponse } from "@/models/Cms";
import { APP_CONFIGS } from "@/main";
import config from '../../configs/configs.json';
import { FrameControl } from "@/models/FrameControl";
import { PostPresignedUrl } from "@/models/ImageAsset";
import { createUrl } from "@/utils/url";

export const CONFIGS: FrameControl = config;
export const PUBLIC_HOST = CONFIGS.api.publicHost;
export const PRIVATE_HOST = CONFIGS.api.privateHost;


export async function getNewContent(): Promise<AxiosResponse<PostResponse>> {
  const searchParams = {
    "populate": "deep",
    "pagination[pageSize]": 5,
    "sort": "publishedAt:DESC"
  }
  const url = createUrl(APP_CONFIGS.cms.endpoints.posts, searchParams)
  return axios.get<PostResponse>(PUBLIC_HOST + '/cms', {
    params: {
      cmsUrl: url
    }
  });
}

export async function getPopularContent(): Promise<AxiosResponse<PostResponse>> {
  const searchParams = {
    "populate": "deep",
    "filters[popular][$eq]": true,
    "pagination[pageSize]": 5,
    "sort": "publishedAt:DESC"
  }
  const url = createUrl(APP_CONFIGS.cms.endpoints.posts, searchParams)
  return axios.get<PostResponse>(PUBLIC_HOST + '/cms', {
    params: {
      cmsUrl: url
    }
  });
}


export async function getPostByTitle(title: string): Promise<AxiosResponse<PostResponse>> {
  const searchParams = {
    "populate": "deep",
    "filters[title][$eq]": title
  }
  const url = createUrl(APP_CONFIGS.cms.endpoints.posts, searchParams)
  return axios.get<PostResponse>(PUBLIC_HOST + '/cms', {
    params: {
      cmsUrl: url
    }
  });
}

export async function getSiteContentByTitle(title: string): Promise<AxiosResponse<PostResponse>> {
  const searchParams = {
    "populate": "deep",
    "filters[title][$eq]": title
  }
  const url = createUrl(APP_CONFIGS.cms.endpoints.siteInfo, searchParams)
  return axios.get<PostResponse>(PUBLIC_HOST + '/cms', {
    params: {
      cmsUrl: url
    }
  });
}

export async function getPostByCategory(category: string | undefined, page: number): Promise<AxiosResponse<PostResponse>> {
  let searchParams = {};
  if (category) {
    searchParams = {
      "populate": "deep",
      "pagination[page]": page,
      "filters[$and][0][category][name][$eq]": category,
      "pagination[pageSize]": 5,
      "sort": "publishedAt:DESC"
    }
  } else {
    searchParams = {
      "populate": "deep",
      "pagination[page]": page,
      "pagination[pageSize]": 5,
      "sort": "publishedAt:DESC"
    }
  }
  const url = createUrl(APP_CONFIGS.cms.endpoints.posts, searchParams)
  return axios.get<PostResponse>(PUBLIC_HOST + '/cms', {
    params: {
      cmsUrl: url
    }
  });
}

export async function getPostBySearch(search: string, page: number): Promise<AxiosResponse<PostResponse>> {
  const searchParams = {
    "populate": "deep",
    "pagination[page]": page,
    "filters[$or][0][title][$containsi]": search,
    "filters[$or][1][content][$containsi]": search,
    "filters[$or][2][description][$containsi]": search,
    "pagination[pageSize]": 5,
    "sort": "publishedAt:DESC"
  }
  const url = createUrl(APP_CONFIGS.cms.endpoints.posts, searchParams)
  return axios.get<PostResponse>(PUBLIC_HOST + '/cms', {
    params: {
      cmsUrl: url
    }
  });
}

export async function getPresignedUrl(contentType: string): Promise<AxiosResponse<PostPresignedUrl>> {
  return axios.get<PostPresignedUrl>(PUBLIC_HOST + `/community/document`, {
      params: {
        contentType: contentType
      }
  });
}