<template>
   <header id="header">
      <div id="header-wrap">
         <div class="container">
            <div class="header-row">
               <!-- Logo -->
               <div id="logo" class="me-lg-5">
                  <a href="/">
                     <img 
                        class="logo-default" 
                        :srcset="`${logoImage}`" 
                        :src="logoImage" 
                        alt="Frame Logo"
                        style="max-height: 70px; padding-top: 10px; padding-bottom: 10px;"
                     >
                  </a>
               </div>
               <!-- User -->
               <div class="header-misc ms-lg-2">
                  <div class="header-misc-icon ps-lg-4">
                     <div class="top-primary-menu">
                        <ul class="menu-container" v-if="!isLoggedIn" style="display: block !important">
                           <li class="menu-item">
                              <a class="menu-link" href="/signIn">
                                 <div>Sign In</div>
                              </a>
                           </li>
                        </ul>
                        <ul class="menu-container header-container" v-else style="display: block !important">
                           <li class="menu-item">
                              <router-link to="/profile" class="menu-link">
                                 <ProfileThumbnail
                                    :user="user"
                                    v-if="!isApiCallInProgress"
                                    :notifications=newNotifications
                                    :width="40"
                                 />
                              </router-link>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <!-- Menu Icon -->
               <div class="primary-menu-trigger">
                  <button class="cnvs-hamburger" type="button" title="Open Mobile Menu">
                     <span class="cnvs-hamburger-box"><span class="cnvs-hamburger-inner"></span></span>
                  </button>
               </div>
               <!-- Primary Nav -->
               <nav class="primary-menu main-primary-menu with-arrows">
                  <ul class="menu-container">
                     <li
                        :class="{'menu-item mega-menu mega-menu-small': page.dropdown, 'menu-item': !page.dropdown}"
                        v-for="page in appConfigs.layout.navbar.pages"
                        :key="page.name"
                        >
                        <a class="menu-link" :href="page.route" v-if="!page.dropdown">
                           <div>
                              {{ page.name }}
                           </div>
                        </a>
                        <a class="menu-link" :href="page.route" v-if="page.dropdown">
                           <div>
                              {{ page.name }}
                           </div>
                        </a>
                        <div class="mega-menu-content" v-if="page.dropdown && !page.muteDropdown">
                           <div class="row g-0 align-items-stretch">
                              <div class="sub-menu-container mega-menu-column col">
                                 <div class="row d-md-flex p-md-4">
                                    <div class="col-md-6">
                                       <div class="menu-item mb-md-2" v-for="category in page.dropdown.slice(0, page.dropdown.length / 2)" :key="category.name">
                                          <a :href="category.route" class="menu-link">
                                             <h4 class="mb-1">
                                                {{ category.name }}
                                             </h4>
                                             <p class="mb-0 fw-normal text-smaller text-black-50">
                                                {{ category.description }}
                                             </p>
                                          </a>
                                       </div>
                                    </div>
                                    <div class="col-md-6">
                                       <div class="menu-item mb-md-2" v-for="category in page.dropdown.slice(Math.floor(page.dropdown.length / 2))" :key="category.name">
                                          <a :href="category.route" class="menu-link">
                                             <h4 class="mb-1">
                                                {{ category.name }}
                                             </h4>
                                             <p class="mb-0 fw-normal text-smaller text-black-50">
                                                {{ category.description }}
                                             </p>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </nav>
               <!-- Secondary Nav -->
               <nav class="primary-menu ms-lg-auto">
                  <ul class="menu-container menu-type-secondary">
                     <li class="menu-item">
                     </li>
                  </ul>
               </nav>
            </div>
         </div>
      </div>
      <div class="header-wrap-clone"></div>
   </header>
</template>

<script lang="ts">
import { APP_CONFIGS } from '@/main';
import { FrameControl } from '@/models/FrameControl';
import store, { DataStore, VuexModuleNamespaces } from '@/store';
import { Vue, Options } from 'vue-class-component';
import ProfileThumbnail from '@/components/user/ProfileThumbnail.vue';
import { Username } from '@/models/User';
import { getUser, getUserNotifications } from '@/service/user';
import { updateUser } from '@/utils/user';

@Options({
  components: {
   ProfileThumbnail,
  }
})
export default class Header extends Vue {

   isApiCallInProgress = false;

   newNotifications = 0;

   smallScreenSize = 991;
   screenSize = window.innerWidth
   isShowHeader = false;
   searchText = "";

   user: Username | null = null;
   
   public async mounted(): Promise<void> {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('xFrame:route', this.handleRouteChange);
      window.addEventListener('xFrame:ack', this.acknowledgeNotification);
      this.handleResize();
      this.handleRouteChange();
      try {
         this.isApiCallInProgress = true;
         await updateUser();
         await this.checkNewUserNotifications();
      } catch(err) {
         console.log(err);
      } finally {
         this.isApiCallInProgress = false;
      }
      if (this.userId) {
         this.user = (await getUser(this.userId)).data;
         this.$forceUpdate();
      }
   }

   public beforeDestroy(): void {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('xFrame:route', this.handleRouteChange);
   }

   public handleRouteChange(): void {
      const pathName = window.location.pathname;
      if (['/signIn', '/signUp', '/resetPassword'].indexOf(pathName) > -1) {
         this.isShowHeader = false;
      } else {
         this.isShowHeader = true;
      }
      this.$forceUpdate();
   }

   public handleResize(): void {
      this.screenSize = window.innerWidth;
   }

   public acknowledgeNotification(): void {
      this.newNotifications = 0;
   }

   public async checkNewUserNotifications(): Promise<void> {
      if (this.isLoggedIn) {
         const unackedNotifications = await (await getUserNotifications(this.userId, undefined)).data.notifications.filter((n) => !n.acknowledged);
         this.newNotifications = unackedNotifications.length;
      }
   }

   public searchPosts(): void {
      if (this.searchText) {
         this.$router.push({
            path: 'search',
            query: {
               query: this.searchText
            }
         });
      }
   }

   get isSmallScreen(): boolean {
      return this.screenSize < this.smallScreenSize;
   }

   get appConfigs(): FrameControl {
      return APP_CONFIGS;
   }

   get isLoggedIn(): boolean {
      return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getIsLoggedIn.name}`];
   }

   get userId(): string {
      return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUserId.name}`];
   }

   get userThumbnail(): string {
      return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUserThumbnail.name}`];
   }

   get logoImage(): string {
      return APP_CONFIGS.assets.logoImage;
   }

   get window(): Window {
      return window;
   }

}
</script>

<style scoped>
#header-wrap .primary-menu-trigger::after {
    content: "" !important;
}
#search {
   margin-bottom: 0 !important;
}
#search i {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
    font-size: 14px;
}
#search input {
    height: 44px;
    width: 85%;
    padding-left: 40px;
    border-radius: 30px;
    color: var(--cnvs-text-color);
    font-family: var(--cnvs-body-font) !important;
    font-weight: 400;
}
.sign-in-button {
   color: var(--cnvs-text-color);
   font-weight: 400;
   font-size: .9325rem;
   font-family: var(--cnvs-body-font) !important;
}
@media (min-width: 992px) {
	.menu-type-primary {
		font-size: 1.125rem;
	}

	.header-misc > .header-misc-icon:nth-child(1) {
		border-left: 1px solid #dee2e6;
	}

	.menu-type-secondary > .menu-item > .menu-link {
		font-size: 0.875rem;
	}

	.menu-type-secondary > .menu-item > .menu-link {
		color: #777;
	}

	.menu-type-secondary > .menu-item:hover > .menu-link,
	.menu-type-secondary > .menu-item.current > .menu-link {
		color: var(--cnvs-themecolor);
	}
}

</style>
