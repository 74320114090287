import { UserNotificationPreferences } from '@/models/User';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Vuex, { ActionTree, GetterTree, MutationTree, ActionContext } from 'vuex'

export interface IDataStoreState {
  errorMessage: string;
  configs: object;
  user: CognitoUser | null;
  username: string | null;
  userThumbnail: string | null;
  userEmail: string | null;
  userNotificationPreferences: UserNotificationPreferences | null;
  userId: string | null;
  userPassword: string | null;
}

export interface IRootState {
  app: IDataStoreState
}

export type IDataStoreGetters = GetterTree<IDataStoreState, IRootState>;

export interface IDataStoreMutations extends MutationTree<IDataStoreState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateConfigs(appState: IDataStoreState, update: any): void;
  updateUser(appState: IDataStoreState, update: CognitoUser): void;
  updateUsername(appState: IDataStoreState, update: string): void;
  updateUserThumbnail(appState: IDataStoreState, update: string): void;
  updateUserId(appState: IDataStoreState, update: string): void;
  updateUserEmail(appState: IDataStoreState, update: string): void;
  updateUserNotifications(appState: IDataStoreState, update: UserNotificationPreferences): void;
  updateUserPassword(appState: IDataStoreState, update: string): void;
}

export interface IDataStoreActions extends ActionTree<IDataStoreState, IRootState> {
  emitApiError(appContext: IAppContext, error: Error): void
}

export type IAppContext = ActionContext<IDataStoreState, IRootState>;

export const DataStore = {
  namespaced: true,
  state: {
    errorMessage: "",
    configs: {},
    user: null,
    username: null,
    userThumbnail: null,
    userEmail: null,
    userNotificationPreferences: null,
    userId: null,
    userPassword: null,
  } as IDataStoreState,
  getters: {
    getIsLoggedIn(appState: IDataStoreState): boolean {
      if (appState.user) {
        return true;
      } else {
        return false;
      }
    },
    getErrorMessage(appState: IDataStoreState): string {
      return appState.errorMessage;
    },
    getConfigs(appState: IDataStoreState): object {
      return appState.configs;
    },
    getUser(appState: IDataStoreState): CognitoUser | null {
      return appState.user;
    },
    getUserId(appState: IDataStoreState): string | null {
      return appState.userId;
    },
    getUsername(appState: IDataStoreState): string | null {
      return appState.username;
    },
    getUserThumbnail(appState: IDataStoreState): string | null {
      return appState.userThumbnail;
    },
    getUserEmail(appState: IDataStoreState): string | null {
      return appState.userEmail;
    },
    getUserPassword(appState: IDataStoreState): string | null {
      return appState.userPassword;
    },
    getUserNotifications(appState: IDataStoreState): UserNotificationPreferences | null {
      return appState.userNotificationPreferences;
    },
  } as IDataStoreGetters,
  mutations: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateConfigs(appState: IDataStoreState, update: any): void {
      appState.configs = update;
    },
    updateUser(appState: IDataStoreState, update: CognitoUser): void {
      appState.user = update;
    },
    updateUsername(appState: IDataStoreState, update: string): void {
      appState.username = update;
    },
    updateUserThumbnail(appState: IDataStoreState, update: string): void {
      appState.userThumbnail = update;
    },
    updateUserId(appState: IDataStoreState, update: string): void {
      appState.userId = update;
    },
    updateUserEmail(appState: IDataStoreState, update: string): void {
      appState.userEmail = update;
    },
    updateUserNotifications(appState: IDataStoreState, update: UserNotificationPreferences | null): void {
      appState.userNotificationPreferences = update;
    },
    updateUserPassword(appState: IDataStoreState, update: string): void {
      appState.userPassword = update;
    },
  } as IDataStoreMutations,
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitApiError(appContext: IAppContext, error: any): void {
      // Update error message
      let appErrorMessage = error.response?.data as string;
      if (!appErrorMessage) {
        appErrorMessage = error.message;
      }

      // Dispatch event
      appContext.state.errorMessage = appErrorMessage;
      const event = new Event('xFrame:error', {
        cancelable: true
      });
      window.dispatchEvent(event);
    }
  } as IDataStoreActions
}

export default new Vuex.Store({
  modules: {
    app: DataStore
  }
})

export const VuexModuleNamespaces = {
  App: "app"
}