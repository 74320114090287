<template>
    <article class="entry" style="display: flex; flex-direction: column; height: 100%; position: relative;">
       <div style="flex: 1 0 auto;">
          <div class="entry-image mb-3">
             <a :href='"/post/" + encodeURIComponent(post.attributes.title)'>
             <img
               :src="post.attributes.thumbnail.data.attributes.formats.small.url"
               loading="lazy"
               class="image-preview"
             >
             </a>
          </div>
       </div>
       <div style="flex: 0 0 auto;">
          <div class="entry-title title-sm">
             <h3>
                <a :href='"/post/" + encodeURIComponent(post.attributes.title)' class="color-underline stretched-link">
                {{ post.attributes.title }}
                </a>
             </h3>
             <div class="entry-categories">
                <a :href='"/post?category=" + post.attributes.category.data.attributes.name'>
                {{ post.attributes.category.data.attributes.name }}
                </a>
             </div>
          </div>
          <div class="entry-meta">
             <ul>
                <li>{{ convertTimeToDate(post.attributes.createdAt) }} by 
                   <a>
                   {{ post.attributes.author.data.attributes.name }}
                   </a>
                </li>
             </ul>
          </div>
          <div class="entry-content mt-3">
             <div class="block-ellipsis">
                {{ post.attributes.description }}
             </div>
             <a :href='"/post/" + encodeURIComponent(post.attributes.title)' class="more-link">Read More</a>
          </div>
       </div>
    </article>
 </template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { Post } from '@/models/Cms';
import { convertTimeToDate } from '@/utils/time';

@Options({
    props: {
        post: {
            required: true,
            type: Object as PropType<Post>
        },
    }
})
export default class ArticlePreview extends Vue {
    
    post!: Post;

   public convertTimeToDate(dateString: string): string {
      return convertTimeToDate(dateString);
    }

}
</script>
  
<style scoped>
.image-preview {
   width: 100%;
   height: 300px;
   object-fit: cover;
   object-position: center;
}
</style>
  