import { Post, PostResponse, SiteInfo } from "@/models/Cms";
import { getNewContent, getPopularContent, getPostByCategory, getPostBySearch, getPostByTitle, getSiteContentByTitle } from "@/service/post";


export async function loadNewContent(): Promise<Post[]> {
    return await (await getNewContent()).data.data
}

export async function loadPopularContent(): Promise<Post[]> {
    return await (await getPopularContent()).data.data
}

export async function queryPostByTitle(title: string): Promise<Post | undefined> {
    const posts = await (await getPostByTitle(title)).data.data
    if (posts.length > 0) {
        return posts[0];
    }
}

export async function getContentByCategory(category: string | undefined, page = 1): Promise<PostResponse> {
    const postResponse = await (await getPostByCategory(category, page)).data;
    return postResponse;
}

export async function getContentBySearch(search: string, page = 1): Promise<PostResponse> {
    const postResponse = await (await getPostBySearch(search, page)).data;
    return postResponse;
}

export async function getSiteInfoByTitle(title: string): Promise<SiteInfo | undefined> {
    const siteInfoResponse = await (await getSiteContentByTitle(title)).data;
    if (siteInfoResponse.data.length > 0) {
        return siteInfoResponse.data[0];
    }
}
